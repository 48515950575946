import { v4 as uuidv4 } from 'uuid';

import {
  V3_SET_PRODUCT_CONFIG,
  V3_REMOVE_LAST_PRODUCT_CONFIG,
  V3_CLEAR_PRODUCT_CONFIG,
  V3_ADD_TO_CART,
  V3_MARK_RETOUCHING_PROMPTED,
  V3_DECLINE_RETOUCHING,
  V3_DECLINE_INTERSTITIAL_PRODUCT,
  V3_DECLINE_PRE_CART_OFFER_PRODUCT,
  V3_MARK_PRE_CART_OFFER_PRODUCT_AS_PROMPTED,
  V3_STORE_CART,
  V3_STORE_CART_ERROR,
  V3_STORE_CART_SUCCESS,
  V3_SUBMITTING_CART,
  V3_SUBMIT_CART_SUCCESS,
  V3_SUBMIT_CART_ERROR,
  V3_CLEAR_CART,
  V3_REMOVE_CART_ITEM,
  V3_RESET_RETOUCHING,
  V3_UPDATE_SMS_NUMBER_CONFIRMED,
  V3_POP_ITEM_FROM_CONFIG,
  V3_REMOVE_ITEM_FROM_CONFIG,
  V3_SET_PERSONALIZATION_ON_ALL_ELIGIBLE_CART_ITEMS,
  V3_SKIP_PERSONALIZATION_ON_ALL_CART_ITEMS,
  V3_CHECKOUT_LOADING,
  V3_APPLY_DISCOUNT_CODE_TO_CART,
  V3_RESET_SUBMITTING_ORDER,
  V3_CONFIRM_SHIPPING_ADDRESS,
} from '../actions/v3Order';
import cartUtils from '../utils/cart';
import _ from 'lodash';
import deepEqual from 'deep-equal';
import cart from '../utils/cart';

const INITIAL_STATE = {
  cartTotals: {
    total: 0,
    shipping: 0,
    tax: 0,
    discount: 0,
    familyDiscount: 0,
    grandTotal: 0,
  },
  checkoutIsLoading: false,
  discountCodes: [],
  offerPicsPlus: false,
  orders: {},
  productConfig: {},
  shippingAddressConfirmed: false,
  smsNumberConfirmed: false,
  storingCart: false,
  submittingOrder: false,
  uuid: null,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  let existing;
  let studentId;
  let output;

  switch (action.type) {
    case V3_ADD_TO_CART: {
      const {
        additionalData,
        cartObject,
        isPrepay,
        selectedStudent,
        shoot,
        personalizationSetToSameValue, // indicates that personalization on all personalized products in the cart should be set to the same value
        personalizationProduct,
      } = action.payload;

      const studentId = selectedStudent.id;

      let setPersonalizationOnAllPersonalizableProductsInCart = false;

      // TODO: update this logic to use a flag set on the studio?
      // We assume that when the personalizationProduct is present that:
      // 1. we establish personalization on all personalizable products in the cart
      if (personalizationProduct) {
        setPersonalizationOnAllPersonalizableProductsInCart = true;
      }

      console.log('V3_ADD_TO_CART');
      console.log('SHOOT', shoot);
      console.log('cartObject', cartObject);
      console.log('personalizationProduct', personalizationProduct);
      console.log(
        'personalizationSetToSameValue',
        personalizationSetToSameValue,
      );

      if (studentId) {
        let newCartObject;
        let newCartItemIds;
        let newPersonalizationProduct;
        let personalization = {};
        let retouching = {};
        let interstitialProductsPrompted = [];
        let preCartOfferProductsPrompted = [];
        let uuid = state.uuid;

        const currentOrder = state.orders[studentId];

        if (currentOrder) {
          const variantId = cartUtils.getNextVariantId(
            currentOrder.cartItems,
            cartObject.productId,
          );

          newCartObject = cartUtils.createNewCartItem(cartObject, variantId);
          newCartItemIds = [...currentOrder.cartItemIds, newCartObject.id];
          retouching = currentOrder.retouching || {};
          personalization = currentOrder.personalization || {};
          interstitialProductsPrompted =
            currentOrder.interstitialProductsPrompted || [];
          preCartOfferProductsPrompted =
            currentOrder.preCartOfferProductsPrompted || [];
        } else {
          newCartObject = cartUtils.createNewCartItem(cartObject);
          newCartItemIds = [newCartObject.id];
          retouching = {
            shouldPrompt: true,
          };
          personalization = {
            shouldPrompt: true,
            value: '',
          };
        }

        console.log(
          'personalizationProduct in reducer',
          personalizationProduct,
        );

        // Check for personalizationProduct and that its id is not the same as cartObject's
        if (
          !cartObject.isPersonalization &&
          personalizationProduct &&
          (personalizationProduct.id !== cartObject.productId ||
            personalizationProduct.productId !== cartObject.productId)
        ) {
          newPersonalizationProduct =
            cartUtils.createNewPersonalizationCartItem(personalizationProduct);

          console.log(
            'THE NEW PERSONALIZATION PRODUCT',
            newPersonalizationProduct,
          );

          // // Check if personalizationProduct is not already in the cart
          // if (
          //   !state.orders[studentId]?.cartItems?.find(
          //     (item) => item.id === newPersonalizationProduct.id,
          //   )
          // ) {
          //   // Add newPersonalizationProduct to cartItems and cartItemIds
          //   newCartItemIds.push(newPersonalizationProduct.id);

          //   console.log(newPersonalizationProduct);

          //   newCartObject = [...newCartObject, newPersonalizationProduct];
          // }
        }

        if (Object.keys(state.orders).length === 0 && uuid === null) {
          uuid = uuidv4(); // Generate UUID for the order
        }

        // Create default order structure
        let newOrder = {
          additionalData,
          productConfig: state.productConfig,
          cartItemIds: newCartItemIds,
          cartItems: [...(currentOrder?.cartItems || []), newCartObject],
          retouching,
          personalization,
          interstitialProductsPrompted,
          preCartOfferProductsPrompted,
          isPrepay: currentOrder?.isPrepay || isPrepay,
          event: currentOrder?.event || {
            id: shoot.event_id,
            dp2JobId: shoot.dp2_job_id,
            name: shoot.name,
            singleChargeForBackgroundUpgrades:
              shoot.single_charge_for_background_upgrades,
            chargeForYpxBackgroundUpgrades:
              shoot.charge_for_ypx_background_upgrades,
          },
          priceList: currentOrder?.priceList || {
            id: shoot.pricing_model.id,
            name: shoot.pricing_model.name,
            shippingPrice: shoot.pricing_model.shipping_price,
          },
          student: currentOrder?.student || {
            id: selectedStudent.id,
            firstName: selectedStudent.firstName || selectedStudent.first_name,
            lastName: selectedStudent.lastName || selectedStudent.last_name,
          },
        };

        if (
          newPersonalizationProduct &&
          !state.orders[studentId]?.cartItems?.find(
            (item) => item.id === newPersonalizationProduct.id,
          )
        ) {
          newOrder = {
            ...newOrder,
            cartItems: [...newOrder.cartItems, newPersonalizationProduct],
            cartItemIds: [
              ...newOrder.cartItemIds,
              newPersonalizationProduct.id,
            ],
          };
        }

        const cartObjectPersonalizationValue = cartObject.personalizationValue;

        // get child item personalization values, filter out null, undefined, and empty strings
        const cartObjectChildPersonalizationValues = (cartObject.children || [])
          .map((child) => child.personalizationValue)
          .filter(
            (value) => value !== undefined && value !== null && value !== '',
          );

        let reviewWhetherCartItemsNeedPersonalizationUpdate = false;
        let submittedPersonalizationValue;

        // If the item being added or the child items have personalization values,
        // get the value and set the flag to review whether the cart items need to be reviewed/updated
        if (
          (cartObjectPersonalizationValue &&
            cartObjectPersonalizationValue !== null &&
            cartObjectPersonalizationValue !== '') ||
          cartObjectChildPersonalizationValues.length > 0
        ) {
          reviewWhetherCartItemsNeedPersonalizationUpdate = true;
          submittedPersonalizationValue =
            cartObjectPersonalizationValue ||
            cartObjectChildPersonalizationValues[0];
        }

        if (reviewWhetherCartItemsNeedPersonalizationUpdate) {
          // In this case, ensure the same value is set on all already-personalized items in the cart
          if (
            personalizationSetToSameValue &&
            !setPersonalizationOnAllPersonalizableProductsInCart
          ) {
            newOrder.cartItems = newOrder.cartItems.map((item) => {
              if (
                submittedPersonalizationValue &&
                submittedPersonalizationValue !== null &&
                submittedPersonalizationValue !== '' &&
                item.personalizationValue &&
                item.personalizationValue !== null &&
                item.personalizationValue !== ''
              ) {
                return {
                  ...item,
                  personalizationValue: submittedPersonalizationValue,
                };
              }

              // Check childProducts
              if (item.children) {
                const updatedChildProducts = item.children.map((childItem) => {
                  if (
                    submittedPersonalizationValue &&
                    submittedPersonalizationValue !== null &&
                    submittedPersonalizationValue !== '' &&
                    childItem.personalizationValue &&
                    childItem.personalizationValue !== null &&
                    childItem.personalizationValue !== ''
                  ) {
                    return {
                      ...childItem,
                      personalizationValue: submittedPersonalizationValue,
                    };
                  }
                  return childItem;
                });
                return { ...item, children: updatedChildProducts };
              }

              return item;
            });
          }

          // In this case, ensure the same value is set on all already-personalized items in the cart AND
          // any non-personalized but personalizable items in the cart
          if (
            personalizationSetToSameValue &&
            setPersonalizationOnAllPersonalizableProductsInCart
          ) {
            newOrder.cartItems = newOrder.cartItems.map((item) => {
              if (
                item.personalizationStatus === 'personalization_optional' ||
                item.personalizationStatus === 'personalization_required'
              ) {
                return {
                  ...item,
                  personalizationValue: submittedPersonalizationValue,
                };
              }

              // Check childProducts
              if (item.children) {
                const updatedChildProducts = item.children.map((childItem) => {
                  if (
                    childItem.personalizationStatus ===
                      'personalization_optional' ||
                    childItem.personalizationStatus ===
                      'personalization_required'
                  ) {
                    return {
                      ...childItem,
                      personalizationValue: submittedPersonalizationValue,
                    };
                  }
                  return childItem;
                });
                return { ...item, children: updatedChildProducts };
              }

              return item;
            });
          }
        }

        // // check if personalizationSetToSameValue is in the payload and update accordingly
        // if (
        //   personalizationSetToSameValue &&
        //   (() ||
        //     (setPersonalizationOnAllPersonalizableProductsInCart &&
        //       cartObjectPersonalizationValue &&
        //       (cartObject.personalizationStatus ===
        //         'personalization_optional' ||
        //         cartObject.personalizationStatus ===
        //           'personalization_required')) ||
        //     cartObjectChildPersonalizationValues.length > 0)
        // ) {

        // }

        return {
          ...state,
          productConfig: {},
          uuid,
          orders: {
            ...state.orders,
            [studentId]: newOrder,
          },
        };
      }

      return { ...state };
    }
    case 'V3_DECLINE_PERSONALIZATION':
      studentId = action.payload.studentId;
      return {
        ...state,
        orders: {
          ...state.orders,
          [studentId]: {
            ...state.orders[studentId],
            personalization: {
              ...state.orders[studentId].personalization,
              shouldPrompt: false,
            },
          },
        },
      };

    case 'V3_SUBMIT_PERSONALIZATION':
      studentId = action.payload.studentId;
      return {
        ...state,
        orders: {
          ...state.orders,
          [studentId]: {
            ...state.orders[studentId],
            personalization: {
              value: action.payload.value,
              shouldPrompt: false,
            },
          },
        },
      };
    case V3_SET_PERSONALIZATION_ON_ALL_ELIGIBLE_CART_ITEMS:
      studentId = action.payload.studentId;
      const submittedPersonalizationValue = action.payload.personalizationValue;

      return {
        ...state,
        orders: {
          ...state.orders,
          [studentId]: {
            ...state.orders[studentId],
            cartItems: state.orders[studentId].cartItems.map((item) => {
              if (
                item.personalizationStatus === 'personalization_optional' ||
                item.personalizationStatus === 'personalization_required'
              ) {
                return {
                  ...item,
                  personalizationValue: submittedPersonalizationValue,
                };
              }

              // Check childProducts
              if (item.children) {
                const updatedChildProducts = item.children.map((childItem) => {
                  if (
                    childItem.personalizationStatus ===
                      'personalization_optional' ||
                    childItem.personalizationStatus ===
                      'personalization_required'
                  ) {
                    return {
                      ...childItem,
                      personalizationValue: submittedPersonalizationValue,
                    };
                  }
                  return childItem;
                });
                return { ...item, children: updatedChildProducts };
              }

              return item;
            }),
            personalization: {
              value: submittedPersonalizationValue,
              shouldPrompt: false,
            },
          },
        },
      };
    case V3_SKIP_PERSONALIZATION_ON_ALL_CART_ITEMS:
      studentId = action.payload.studentId;

      return {
        ...state,
        orders: {
          ...state.orders,
          [studentId]: {
            ...state.orders[studentId],
            cartItems: state.orders[studentId].cartItems.map((item) => {
              if (
                item.personalizationStatus === 'personalization_optional' ||
                item.personalizationStatus === 'personalization_required'
              ) {
                return {
                  ...item,
                  personalizationValue: null,
                };
              }

              // Check childProducts
              if (item.children) {
                const updatedChildProducts = item.children.map((childItem) => {
                  if (
                    childItem.personalizationStatus ===
                      'personalization_optional' ||
                    childItem.personalizationStatus ===
                      'personalization_required'
                  ) {
                    return {
                      ...childItem,
                      personalizationValue: null,
                    };
                  }
                  return childItem;
                });
                return { ...item, children: updatedChildProducts };
              }

              return item;
            }),
            personalization: {
              value: null,
              shouldPrompt: false,
            },
          },
        },
      };
    case V3_MARK_RETOUCHING_PROMPTED:
    case V3_DECLINE_RETOUCHING: {
      const { studentId } = action.payload;
      const order = state.orders[studentId];

      if (!order) {
        return state;
      }

      const updatedOrder = {
        ...order,
        retouching: {
          ...order.retouching,
          shouldPrompt: false,
        },
      };

      return {
        ...state,
        orders: {
          ...state.orders,
          [studentId]: updatedOrder,
        },
      };
    }
    case V3_DECLINE_INTERSTITIAL_PRODUCT: {
      const { studentId } = action.payload;
      const { productId } = action.payload;
      const order = state.orders[studentId];

      if (!order) {
        return state;
      }

      const promptedProducts = order.interstitialProductsPrompted || [];
      const productAlreadyExists = promptedProducts.includes(productId);

      const updatedOrder = {
        ...order,
        interstitialProductsPrompted: productAlreadyExists
          ? promptedProducts
          : [...promptedProducts, productId],
      };

      return {
        ...state,
        orders: {
          ...state.orders,
          [studentId]: updatedOrder,
        },
      };
    }
    case V3_DECLINE_PRE_CART_OFFER_PRODUCT:
    case V3_MARK_PRE_CART_OFFER_PRODUCT_AS_PROMPTED: {
      {
        const { studentId } = action.payload;
        const { productId } = action.payload;
        const order = state.orders[studentId];

        if (!order) {
          return state;
        }

        const promptedProducts = order.preCartOfferProductsPrompted || [];
        const productAlreadyExists = promptedProducts.includes(productId);

        const updatedOrder = {
          ...order,
          preCartOfferProductsPrompted: productAlreadyExists
            ? promptedProducts
            : [...promptedProducts, productId],
        };

        return {
          ...state,
          orders: {
            ...state.orders,
            [studentId]: updatedOrder,
          },
        };
      }
    }
    case V3_SUBMIT_CART_ERROR: {
      return {
        ...state,
        submittingOrder: false,
      };
    }
    case V3_SUBMIT_CART_SUCCESS: {
      return {
        ...state,
        submittingOrder: false,
      };
    }
    case V3_SUBMITTING_CART: {
      return {
        ...state,
        submittingOrder: true,
      };
    }
    case V3_STORE_CART: {
      return {
        ...state,
        storingCart: true,
      };
    }
    case V3_STORE_CART_ERROR: {
      return {
        ...state,
        storingCart: false,
      };
    }
    case V3_STORE_CART_SUCCESS: {
      return {
        ...state,
        storingCart: false,
        cartTotals: action.payload,
      };
    }
    case V3_SET_PRODUCT_CONFIG: {
      const {
        index,
        isPrepay,
        itemToAdd,
        prepayRequiresSameValuePersonalization,
      } = action.payload;

      const itemToAddKey = Object.keys(itemToAdd)[0];

      let productConfig;

      // If the shoot is prepay and prepay requires the same value for personalization
      // across all cart items, ensure the config is updated with the same value for each
      // child product's personalization.
      if (
        itemToAddKey?.toLowerCase() === 'personalization' &&
        isPrepay &&
        prepayRequiresSameValuePersonalization &&
        itemToAdd.personalization !== undefined &&
        itemToAdd.personalization !== null &&
        itemToAdd.personalization !== ''
      ) {
        productConfig = Object.entries(state.productConfig).reduce(
          (newProductConfig, [key, value]) => {
            return {
              ...newProductConfig,
              [key]: {
                ...value,
                personalization:
                  value.personalization !== undefined &&
                  value.personalization !== null
                    ? itemToAdd.personalization
                    : value.personalization,
              },
            };
          },
          {},
        );
      } else {
        // If the conditions aren't met, just use the original productConfig
        productConfig = state.productConfig;
      }

      return {
        ...state,
        productConfig: {
          ...productConfig,
          [index]: productConfig[index]
            ? {
                ...productConfig[index],
                ...itemToAdd,
              }
            : itemToAdd,
        },
      };
    }
    case V3_POP_ITEM_FROM_CONFIG:
      existing = { ...state };
      if (!existing.productConfig[action.payload.index]) return existing;
      if (!existing.productConfig[action.payload.index][action.payload.section])
        return existing;
      delete existing.productConfig[action.payload.index][
        action.payload.section
      ];
      return existing;
    case V3_REMOVE_ITEM_FROM_CONFIG:
      existing = { ...state };
      const keys = Object.keys(
        existing.productConfig[action.payload.index],
      ).length;
      Object.keys(existing.productConfig).forEach((key, index) => {
        if (index < action.payload.index) return;
        else if (existing.productConfig[index + 1])
          existing.productConfig[index] = existing.productConfig[index + 1];
        else delete existing.productConfig[index];
      });
      action.payload.history.go(-keys);
      return existing;
    case V3_CLEAR_PRODUCT_CONFIG:
      let productConfig;

      if (action.payload) {
        productConfig = {
          ...state.productConfig,
          [action.payload]: [],
        };
      } else {
        productConfig = {};
      }

      return {
        ...state,
        productConfig,
      };
    case V3_CLEAR_CART: {
      return {
        ...state,
        cartTotals: {},
        discountCodes: [],
        orders: {},
        shippingAddressConfirmed: false,
        uuid: null,
      };
    }
    case V3_REMOVE_CART_ITEM: {
      const { studentId, cartItemId } = action.payload;
      const studentOrder = state.orders[studentId];

      if (!studentOrder) {
        return state; // No changes, studentId not found.
      }

      const cartItemToRemove = studentOrder.cartItems.find(
        (item) => item.id === cartItemId,
      );

      if (!cartItemToRemove) {
        return state; // No changes, cartItemId not found.
      }

      let updatedCartItems = studentOrder.cartItems;

      if (cartItemToRemove.isPersonalization) {
        // Iterate through all other items in the order and set personalizationValue to null,
        // also handle the child items if they exist.
        updatedCartItems = studentOrder.cartItems.map((item) => ({
          ...item,
          personalizationValue: null,
          children: item.children
            ? item.children.map((childItem) => ({
                ...childItem,
                personalizationValue: null,
              }))
            : item.children,
        }));
      }

      // TODO: seems like we can remove this, but need to test
      const newCartItemIds = studentOrder.cartItemIds.filter(
        (id) => id !== cartItemId,
      );

      const newCartItems = updatedCartItems.filter(
        (item) => item.id !== cartItemId,
      );

      // Remove the productId from interstitialProductsPrompted if it exists.
      const newInterstitialProductsPrompted =
        studentOrder.interstitialProductsPrompted.filter(
          (id) => id !== cartItemToRemove.productId,
        );

      // Compute the total sum of the non-incentive cart items.
      const totalNonIncentiveCartItems = newCartItems.reduce(
        (total, item) => total + (item.isIncentive ? 0 : item.price),
        0,
      );

      // Remove any incentive items where the incentiveThreshold is lower than
      // the new sum of non-incentive cart items.
      let filteredIncentiveCartItems = newCartItems.filter(
        (item) =>
          !item.isIncentive ||
          item.incentiveThreshold <= totalNonIncentiveCartItems,
      );

      const allIncentiveItems = filteredIncentiveCartItems.every(
        (item) => item.isIncentive,
      );

      // If all remaining items are incentive items, set filteredIncentiveCartItems to an empty array
      if (allIncentiveItems) {
        filteredIncentiveCartItems = [];
      }

      const filteredIncentiveCartItemIds = filteredIncentiveCartItems.map(
        (item) => item.id,
      );

      // Create new studentOrder with updated cartItemIds and cartItems.
      const newStudentOrder = {
        ...studentOrder,
        cartItemIds: filteredIncentiveCartItemIds,
        cartItems: filteredIncentiveCartItems,
        interstitialProductsPrompted: newInterstitialProductsPrompted,
      };

      const newOrders = { ...state.orders, [studentId]: newStudentOrder };

      // If cartItems is empty, delete the student order
      if (filteredIncentiveCartItems.length === 0) {
        delete newOrders[studentId];
      }

      return {
        ...state,
        orders: newOrders,
      };
    }
    case V3_RESET_RETOUCHING: {
      const { studentId } = action.payload;
      const studentOrder = state.orders[studentId];

      if (!studentOrder) {
        return state; // No changes, studentId not found.
      }

      const newRetouching = studentOrder.retouching;
      newRetouching.shouldPrompt = true;

      const newStudentOrder = {
        ...studentOrder,
        retouching: newRetouching,
      };

      const newOrders = { ...state.orders, [studentId]: newStudentOrder };

      return {
        ...state,
        orders: newOrders,
      };
    }
    case V3_UPDATE_SMS_NUMBER_CONFIRMED: {
      return {
        ...state,
        smsNumberConfirmed: action.payload,
      };
    }
    case V3_CHECKOUT_LOADING: {
      const { checkoutIsLoading } = action.payload;

      return {
        ...state,
        checkoutIsLoading,
      };
    }
    case V3_APPLY_DISCOUNT_CODE_TO_CART: {
      return cartUtils.applyDiscountCodeToCart(state, action.payload.data);
    }
    case V3_RESET_SUBMITTING_ORDER: {
      return {
        ...state,
        submittingOrder: false,
      };
    }
    case V3_CONFIRM_SHIPPING_ADDRESS: {
      return {
        ...state,
        shippingAddressConfirmed: true,
      };
    }
    default:
      return state;
  }
}
