import React from 'react';
import Swal from 'sweetalert2';

const AddToCartButton = ({ addProductToCart, canBeSubmitted }) => {
  if (!canBeSubmitted()) {
    return (
      <button
        className="bnl-product-config__button button awaiting-config"
        onClick={() => {
          Swal.fire(`Please finish configuring your product`);
          return false;
        }}
      >
        Add to Cart
      </button>
    );
  }

  return (
    <button
      className="bnl-product-config__button  button"
      onClick={() => {
        addProductToCart();
      }}
    >
      Add to Cart
    </button>
  );
};

export default AddToCartButton;
